<template>
  <div class="page">
    <van-cell-group>
      <van-cell :value="user.username" title="用户名"></van-cell>
      <van-cell :value="user.userfen" title="您的点数">
        <div>
          <span v-text="user.userfen"></span>
          <van-icon name="gem-o" class="danger ml-5"></van-icon>
        </div>
      </van-cell>
      <van-cell :value="user.money" title="现金余额"></van-cell>
      <van-cell :value="user.registertime" title="注册时间"></van-cell>
      <van-cell
        is-link
        :value="user.mobile || '未绑定'"
        title="手机号码"
        @click="showBind('mobile')"
      ></van-cell>
      <van-cell
        is-link
        :value="user.email || '未绑定'"
        title="邮箱"
        @click="showBind('email')"
      ></van-cell>
    </van-cell-group>

    <van-action-sheet v-model="popVisible" round class="popup">
      <van-cell title-class="tc" :title="types[bindType].text"></van-cell>
      <bind-component :bindType="bindType" @change="change" />
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import { ActionSheet } from "vant";
Vue.use(ActionSheet);

import BindComponent from "./Bind/index.vue";

export default {
  name: "userInfo",
  components: { BindComponent },
  data() {
    return {
      user: {},
      bindType: "mobile",
      popVisible: false,
      types: {
        mobile: {
          text: "绑定手机号",
          codeUrl: "send_sms_code",
        },
        email: {
          text: "绑定邮箱",
          codeUrl: "send_email_code",
        },
      },
    };
  },
  computed: {
    fromPath: function () {
      return this.$route.params.fromPath;
    },
  },
  methods: {
    getData() {
      this.$http.get("user/index").then((res) => {
        if (res.code === 1) {
          this.user = res.data;
        } else {
          this.$router.goBack();
        }
      });
    },
    showBind(type) {
      this.bindType = type;
      this.popVisible = true;
    },
  },
  activated() {
    this.getData();
  },
};
</script>

<style></style>
